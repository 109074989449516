import React, { useState } from "react";
import {
  DefaultButton,
  FormButton,
  FormInputWrapper,
  MDTForm,
  MDTModal,
  ModalContent,
  ModalTitle,
  ModalWrapper,
  LoginError,
} from "../../../styles/StyledComponents";
import changePassword from "../../../mdtApi/changePassword";
import { useWarning } from "../../../util/hooks";
import { navigate } from "gatsby";

const ResetPassword = ({ location }) => {
  const [warning, createWarning] = useWarning();
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState(
    "Enter a new password to reset your account information."
  );
  const [hideForm, setHideForm] = useState(false);

  const handleInput = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (inputs["pw1"] !== inputs["pw2"]) return alert("Passwords must match");
    const parsed = new URLSearchParams(location.search);
    const code = parsed.get("code");
    const options = {
      password: inputs["pw1"],
      code: code as string,
    };
    (async () => {
      const res = await changePassword(options);
      if (res.status !== 200) {
        createWarning(res.reason);
      } else {
        setContent("Successfully changed password.");
        setHideForm(true);
      }
      setLoading(false);
    })();
  };

  return (
    <ModalWrapper>
      <MDTModal>
        <ModalTitle>Reset Password</ModalTitle>
        <ModalContent>{content}</ModalContent>
        {warning ? (
          <LoginError style={{ margin: "0px" }}>{warning}</LoginError>
        ) : null}
        {hideForm ? (
          <ModalContent>
            <DefaultButton
              onClick={() => {
                navigate("/");
              }}
            >
              Return Home
            </DefaultButton>
          </ModalContent>
        ) : (
          <MDTForm onSubmit={handleSubmit}>
            <FormInputWrapper>
              <input
                name="pw1"
                type="password"
                placeholder="New Password"
                onChange={handleInput}
                required
              />
              <input
                name="pw2"
                type="password"
                placeholder="New Password"
                onChange={handleInput}
                required
              />
              <FormButton
                disabled={loading}
                type="submit"
                value={loading ? "Submitting..." : "Reset Password"}
              />
            </FormInputWrapper>
          </MDTForm>
        )}
      </MDTModal>
    </ModalWrapper>
  );
};

export default ResetPassword;
