import * as React from "react";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import ResetPassword from "../components/AuthComponents/ForgotPassword/ResetPassword";

const ResetPasswordPage = ({ location }) => {
  return (
    <Layout vertical={true}>
      <Seo title="Reset Password" />
      <ResetPassword location={location} />
    </Layout>
  );
};

export default ResetPasswordPage;
