import { devAPI } from "../util/urls";

export default async ({
  code,
  password,
}: {
  code: string;
  password: string;
}) => {
  const config: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code, password }),
  };
  try {
    const res = await fetch(`${devAPI}/users/reset_password`, config);
    const data = await res.json();
    return { result: data, status: res.status };
  } catch (err) {
    return err;
  }
};
